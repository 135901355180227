/* 
  Bootstrap Custom Menu
  Copyright 2023-2028 Devph.io
  Author Erwin T. Agpasa
  Released on: December 16, 2022
*/

#main_nav{
  z-index: 99999;
  position: fixed; /* Make it stick/fixed */
  top: 0; /* Stay on top */
  width: 100%; /* Full width */
  transition: top 0.6s; /* Transition effect when sliding down (and up) */
  }

.nav-link{
  cursor: pointer;
  }

.navbar-dark {
  background-color: #1b212d!important;
  }
 
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  margin-right: 10px;
  }

.dropdown-menu{
  margin-top: 2px!important;
  }

.navbar-brand img {
  max-width: 40px;
  filter: blur(0);
  -webkit-filter: blur(0);
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  }

.navbar-toggler-icon {
  width: 20px;
  height: 25px;
  }

.navbar-nav .nav-item {
  margin-right: 20px;
  font-size: 14px;
  color: #3F3F44!important;
  }

.dropdown-item {
  font-size: 14px;
  color: rgb(221, 221, 221)!important;
  }

/*Active drop menu color*/
.dropdown-menu>.active>a, 
.dropdown-menu>.active>a:visited,
.dropdown-menu>.active>a:hover, 
.dropdown-menu>.active>a:focus{
   color: rgb(221, 221, 221)!important;
   background-color: #222222!important;
  }

.dropdown-menu> li> a:hover{
  background-color: #222222!important;
  }

.dropdown-menu {
  background-color: #303030!important;
  border: none!important;
  border-top-right-radius: 0!important;
  border-top-left-radius: 0!important;
  }

@media all and (min-width: 992px) {
  .container-fluid{
    margin-left: 50px;
    margin-right: 50px;
  }
	.dropdown-menu li{
		position: relative;
	  }

	.dropdown-menu .submenu{ 
		display: none;
		position: absolute;
		left:100%; top:-7px;
	  }

	.dropdown-menu .submenu-left{ 
		right:100%; left:auto;
	  }

	.dropdown-menu > li:hover{ 
    background-color: #474747 
  }
	.dropdown-menu > li:hover > .submenu{
		display: block;
	  }
}	

@media (max-width: 991px) {
 
  .dropdown-menu .dropdown-menu{
	  margin-left:0.7rem; margin-right:0.7rem; margin-bottom: .5rem;
    }

  .navbar-nav .nav-item {
    margin-left: 0px;
    }
    .dropdown-menu {
      border: 0px solid #ddd;
    }
}	

@media (max-width: 767.98px) {
  .navbar-nav .nav-item {
    margin-left: 0px;
    }
}

@media (max-width: 575.98px) {
  .navbar-nav .nav-item {
    margin-left: 0px;
    }
}


