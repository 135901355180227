.hero{
  margin-top: 240px;
  }

.heading p{
  color: #fff;
  } 

.heading h2 {
  font-weight: 700;
  font-size: 72px;
  color: #c1c1c1;
  }