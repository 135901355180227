.portfolio-img {
  opacity: 0.6;
}

.portfolio-img:hover {
  opacity: 0.9;
}

.portfolio-img img {
  border-radius: 4px;
}

.list-group li {
  font-size: 9pt;
}


.portfolio-wrapper-left .description .text-inner,
.portfolio-wrapper-right .description .text-inner {
  background-color: #000;
  border: 0px solid #181818 !important;
  padding: 15px;
  border-radius: 8px;
}


.portfolio-wrapper-left .right-list .list-group-item {
  padding-right: 0px;
  margin-left: 22px;
}

.portfolio-wrapper-left .left-list .list-group-item {
  padding-right: 0px;
  margin-left: 22px;
}


.portfolio-all {
  margin-bottom: 150px;
}




@media (max-width: 576px) {
  .video-desktop {
    display: none;
  }

  .portfolio-all {
    margin-bottom: 100px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .video-mobile {
    display: none;
  }


  .portfolio-wrapper-left {
    flex: 0 0 40%;
  }

  .portfolio-wrapper-right {
    flex: 0 0 40%;
  }

  .portfolio-img {
    flex: 0 0 60%;
  }

  .portfolio-wrapper-left .description {
    margin-left: -250px;
    position: relative;
    z-index: 100;
  }

  .portfolio-wrapper-right .description {
    margin-right: -220px;
    position: relative;
    z-index: 100;
  }
}




/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .video-mobile {
    display: none;
  }


}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}


/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}


/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}