.decorated {
  overflow: hidden;
}

.decorated>span {
  position: relative;
  display: inline-block;
}

.decorated>span:after {
  content: '';
  position: absolute;
  top: 50%;
  border-bottom: 1px #202020 solid;
  width: 100vw;
  margin: 0 20px;
}

.decorated>span:after {
  left: 100%;
}

.list-group-item {
  background: #000 !important;
  border: none !important;
  color: #6c717e !important;
  padding-left: 0 !important;
}

.me {
  margin-top: 20px;
  /* opacity: 0.5; */
}

.me:hover {
  /* opacity: 0.8; */
  cursor: pointer;
}