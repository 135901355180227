/* .overlay::before{
  color: rgb(27 33 45), rgba(255, 255, 255, 0)!important;
  --gradient-color: rgb(27 33 45), rgba(255, 255, 255, 0);
    --gradient-width: 200px;
}

.overlay::after{
  color: rgb(27 33 45), rgba(255, 255, 255, 0)!important;
  --gradient-color: rgb(27 33 45), rgba(255, 255, 255, 0);
    --gradient-width: 200px;
} */

.work-wrapper ul li {
  margin-top: 20px;
}

.work-nav {
  color: #c1c1c1;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  border-left: 1px solid #b4b4b4 !important;
  border-radius: 0 !important;
  background: none !important;
  color: #cdcdcd !important;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding-left: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.work-nav-link {
  border-left: 1px solid #727272 !important;
  border-radius: 0 !important;
}

.tab-content {
  margin-top: 10px;
  color: #6d757d;
}