@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&family=Roboto:wght@100;300;500;700;900&display=swap');

html {
  scroll-behavior: smooth;
  ;
}

body {
  background-color: #000 !important;
  /* background-color: #1b212d!important; */
  /* background-color: #1b212d!important; */
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.overlay::before,
.overlay::after {
  --gradient-color: rgb(0 0 0), rgba(255, 255, 255, 0) !important;
}


a {
  text-decoration: none !important;
}

a:hover,
.btn-outline-secondary:hover {
  color: #fff !important;
}

.navbar-dark2 {
  background-color: #000 !important;
}

.navbar-light {
  z-index: 99999 !important;
  background-color: #0f121d !important;
}

.sidebar {
  z-index: -1;
  min-height: 100vh;
  background-color: #0f121d !important;
}

.inner-page {
  background-color: #0f121d !important;
}

.text-blue {
  color: #1c4fd8;
}

.btn-primary {
  margin-left: 20px;
  color: #fff;
  background-color: #000 !important;
  border: #1c4fd8 !important;
}

.btn-primary:hover {
  color: #1c4fd8 !important;
}

.btn-secondary {
  color: #fff !important;
  background-color: #122e7c !important;
  border: #1c4fd8 !important;
}

.btn-secondary:hover {
  color: #fff !important;
  background-color: #1c4fd8 !important;
  border: #1943b7 !important;
}

.text-secondary,
a {
  color: #6d757d !important;
}

.text-info {
  color: #a19d9d !important;
}

.btn {
  font-size: 15px !important;
}

.spacer {
  margin-top: 200px;
}

.card {
  border-radius: 3 !important;
  background: #000 !important;
  border-color: #181818 !important;
}

.card-body {
  height: 200px;
}

.card {
  transition: 0.3s;
}

.card:hover {
  transform: translate(0, -10px);
}

@media (max-width: 991px) {
  .spacer {
    margin-top: 100px;
  }
}